import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    const styles = {
        container: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: 'rgba(82, 63, 30, 1)',
            width: '100%',
        },
        text: {
            textAlign: 'center',
            color: '#FFF',
            marginBottom: 0,
        },
        footerLink: {},
    };

    return (
        <div className="container-fluid footer-container" style={styles.container}>
            <p style={styles.text}>{`Copyright © ${new Date().getFullYear()} by FMOsoft`}</p>
            <p style={styles.text}>
                <Link to={'/'}><span style={styles.footerLink}>Home</span></Link> | <Link to={'/map-creator'}>Map Creator</Link> | <Link to={'/image-finder'}>Image Finder</Link> | <Link to={'/download'}>Download</Link> | <Link to={'/contact'}>Support / Contact Us</Link>
            </p>
        </div>
    );
};

export default Footer;
