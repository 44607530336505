import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import superagent from 'superagent';

import '../node_modules/sweetalert/dist/sweetalert.css';
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
import './css/font-awesome.css';
import './css/main.css';

import handleError from './util/handle-error';
import appReducer from './reducers/app-reducer';
import App from './App';
import Home from './components/home';
import MapCreator from './components/map-creator';
import ImageFinder from './components/image-finder/image-finder';
import DownloadMapCreator from './components/download/mapcreator';
import Contact from './components/contact';
import { addBuilds, addFiles } from './actions/app-actions';

const store = createStore(appReducer);

store.subscribe(() => console.log('state', store.getState()));

// For development
// const host = 'http://localhost:3030';

// For production
const host = window.location.origin;

// Get Image Finder builds
const foundObj = {};

superagent
    .get('/api/image-finder-builds')    // production
    // .get('http://localhost:3030/api/image-finder-builds') // development
    .end((err, res) => {
        if (err) {
            console.error(err);
            alert('There was an error loading the Image Finder builds. Please contact help@fmosoft.com if the problem persists.');
            return;
        }
        const builds = JSON.parse(res.text);
        const uniqueBuilds = builds
            .filter(b => {
                const escapedLink = JSON.stringify(b.link);
                if (!foundObj[escapedLink]) {
                    foundObj[escapedLink] = 1;
                    return true;
                } else {
                    return false;
                }
            })
            .map(b => ({
                ...b,
                arch: b.platform.endsWith('64') ? 64 : 32,
                extendedPlatform: [b.platform.startsWith('windows') ? 'Windows' : b.platform.startsWith('macos') ? 'macOS' : 'linux'],
                platform: b.platform.startsWith('windows') ? 'windows' : b.platform.startsWith('macos') ? 'macos' : 'linux',
                key: b.awsKey,
                createdAt: b.date,
            }));
        store.dispatch(addBuilds(uniqueBuilds));
    });

// Get Map Creator builds
const api = feathers()
    .configure(rest(host).superagent(superagent));

const buildsService = api.service('builds');
buildsService.find({query: {platform: {$ne: 'linux'}}})
    .then(data => {
        const preppedData = data
            .map(b => ({
                ...b,
                link: `https://s3.amazonaws.com/fmosoft/${b.key}`,
                type: 'map-creator',
            }));
        store.dispatch(addBuilds(preppedData));
    })
    .catch(err => handleError(err));

const filesService = api.service('files');
filesService.find()
    .then((res) => {
        res.forEach(b => {
            store.dispatch(addFiles([...b]));
        });
    })
    .catch(err => handleError(err));

const browserHistory = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <Router history={browserHistory}>
          <Routes>
            <Route path="/" element={<App />}>
                <Route exact={true} path="/" element={<Home />} />
                <Route path="map-creator" element={<MapCreator />} />
                <Route path="image-finder" element={<ImageFinder />} />
                <Route path="download" element={<DownloadMapCreator />} />
                <Route path="contact" element={<Contact />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: '1rem' }}>
                    <p>This url is not valid.</p>
                  </main>
                } />
            </Route>
          </Routes>
        </Router>
    </Provider>,
    document.getElementById('root'),
);
