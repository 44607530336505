import { connect } from 'react-redux';
import DownloadMapCreator from './download-mapcreator';

const DownloadMapCreatorContainer = connect(
    appState => {
        return {
            builds: appState.builds,
            files: appState.files,
        };
    },
)(DownloadMapCreator);

export default DownloadMapCreatorContainer;
