import React from 'react';
import {Link} from 'react-router-dom';
import mapCreatorIcon from '../../public/images/map-creator.png';
import imageFinderIcon from '../../public/images/literacy_images.png';

const Home = () => {
    const styles = {
        container: {
            marginTop: 10,
        },
        img: {
            maxWidth: '100%',
        },
    };

    return (
        <div>
            {/*<PageHeader><span>Map Creator</span></PageHeader>*/}
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 text-center" style={styles.container}>
                            <div className="col-md-6">
                                <Link to="/map-creator">
                                    <img style={styles.img} src={mapCreatorIcon} alt="Map Creator" />
                                    <h3>Map Creator</h3>
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <Link to="/image-finder">
                                    {/* eslint-disable-next-line */}
                                    <img style={styles.img} src={imageFinderIcon} alt="Image Finder"/>
                                    <h3>Image Finder</h3>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Home.propTypes = {};

export default Home;
