export const addBuilds = (builds = []) => ({
    type: 'ADD_BUILDS',
    payload: {
        builds,
    },
});
export const addFiles = (files = []) => ({
    type: 'ADD_FILES',
    payload: {
        files,
    },
});
