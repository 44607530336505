import PropTypes from 'prop-types';
import React from 'react';
import PageHeader from '../../shared/page-header';
import moment from 'moment';

const sortByVersion = arr => {
    return arr
        .concat()
        .sort((a, b) => {
            const versionA = a.version;
            const versionB = b.version;
            const splitA = versionA
                .split('.')
                .map(n => parseInt(n, 10));
            const splitB = versionB
                .split('.')
                .map(n => parseInt(n, 10));
            const [ majorA, minorA, patchA ] = splitA;
            const [ majorB, minorB, patchB ] = splitB;
            if(majorA === majorB) {
                if(minorA === minorB) {

                    if(patchA === patchB) {
                        return 0;
                    } else {
                        return patchA > patchB ? 1 : -1;
                    }
                } else {
                    return minorA > minorB ? 1 : -1;
                }
            } else {
                return majorA > majorB ? 1 : -1;
            }
        });
};

// const getFedoraVersion = name => {
//     return parseInt(name.match(/Fedora\s+(\d+)/)[1], 10);
// };
// const getUbuntuVersion = name => {
//     return parseInt(name.match(/Ubuntu\s+([\d\.]+)/)[1], 10);
// };

const formatDate = dateStr => {
    const date = moment(new Date(dateStr));
    return date.format('MMMM D, YYYY');
};

const DownloadMapCreator = ({ builds = [], files = [] }) => {

    const styles = {
        downloadListItem: {
            paddingTop: 5,
            paddingBottom: 5,
        },
        warning: {
            marginBottom: 20,
        },
    };

    const tutorialVideo = files.find(f => /\.mp4$/.test(f.key)) || {};

    const sizeHash = files
        .reduce((obj, f) => ({
            ...obj,
            [f.key]: (f.size / 1000000).toFixed(1) + ' MB',
        }), {});

    const preppedBuilds = builds
        .map(b => ({
            ...b, size: sizeHash[b.key],
        }));
    const sortedBuilds = sortByVersion(preppedBuilds).reverse();

    const latestWindowsVersion = {};

    const getWindowsBuildItems = (type, appName, buildsToFilter) => {
        let windowsBuilds = buildsToFilter
            .filter(b => b.platform === 'windows' && b.type === type);
        let windowsBuildItems = [];
        if(windowsBuilds.length > 0) {

            latestWindowsVersion[type] = windowsBuilds[0].version;

            windowsBuilds = windowsBuilds
                .filter(b => b.version === latestWindowsVersion[type]);

            const win32Build = windowsBuilds.find(b => b.arch === 32);
            const win64Build = windowsBuilds.find(b => b.arch === 64);
            windowsBuildItems = [win64Build, win32Build]
                .filter(b => !!b)
                .map(b => {
                    return (
                        <li style={styles.downloadListItem} key={b.key}>
                            <a href={b.link}><strong>{`${appName} v${b.version} for ${b.arch}-bit ${b.extendedPlatform.join(' / ')}`}</strong></a>
                            <span className="text-muted">{` - ${formatDate(b.createdAt)} | ${b.size}`}</span>
                        </li>
                    );
                });
        }

        return windowsBuildItems;
    };

    const getMacosBuildItems = (type, appName, buildsToFilter) => {
        let macosBuilds = buildsToFilter
            .filter(b => b.platform === 'macos' && b.type === type);
        let macosBuildItems = [];
        if(macosBuilds.length > 0) {

            const latestMacosVersion = latestWindowsVersion[type];

            macosBuilds = macosBuilds
                .filter(b => b.version === latestMacosVersion);

            const macosDMG = macosBuilds.find(b => /\.dmg$/.test(b.key)) || {};
            const macosISO = macosBuilds.find(b => /\.iso$/.test(b.key)) || {};
            macosBuildItems = [macosDMG, macosISO]
                .filter(b => !!b.key)
                .map((b, i) => {
                    return (
                        <li style={styles.downloadListItem} key={b.key}>
                            <a href={b.link}><strong>{`${appName} v${b.version} for ${b.arch}-bit ${b.extendedPlatform.join(' / ')} - ${i === 0 ? 'dmg' : 'DVD image'}`}</strong></a>
                            <span className="text-muted">{` - ${formatDate(b.createdAt)} | ${b.size}`}</span>
                        </li>
                    );
                });
        }

        return macosBuildItems;
    };

    const mapCreatorWindowsBuildItems = getWindowsBuildItems('map-creator', 'Map Creator', sortedBuilds);
    const imageFinderWindowsBuildItems = getWindowsBuildItems('image-finder', 'Image Finder', sortedBuilds);
    const mapCreatorMacosBuildItems = getMacosBuildItems('map-creator', 'Map Creator', sortedBuilds);
    const imageFinderMacosBuildItems = getMacosBuildItems('image-finder', 'Image Finder', sortedBuilds);

    // const ubuntuBuilds = sortedBuilds
    //     .filter(b => b.platform === 'linux' && b.extendedPlatform.some(p => /ubuntu/i.test(p)))
    //     .sort((a, b) => {
    //         const osVersionA = getUbuntuVersion(a.extendedPlatform[a.extendedPlatform.findIndex(p => /ubuntu/i.test(p))]);
    //         const osVersionB = getUbuntuVersion(b.extendedPlatform[b.extendedPlatform.findIndex(p => /ubuntu/i.test(p))]);
    //         return osVersionA === osVersionB ? 0 : osVersionA > osVersionB ? -1 : 1;
    //     });
    // let ubuntuBuildItems = [];
    // if(ubuntuBuilds.length > 0) {
    //
    //     const latestUbuntuVersion = ubuntuBuilds[0].version;
    //
    //     ubuntuBuildItems = ubuntuBuilds
    //         .filter(b => b.version = latestUbuntuVersion)
    //         .map(b => {
    //             return (
    //                 <li style={styles.downloadListItem} key={b.key}>
    //                     <a href={b.link}><strong>{`Map Creator v${b.version} for ${b.arch}-bit ${b.extendedPlatform.join(' / ')}`}</strong></a>
    //                     <span className="text-muted">{` - ${formatDate(b.createdAt)} | ${b.size}`}</span>
    //                 </li>
    //             );
    //         });
    // }
    //
    // const openSuseBuilds = sortedBuilds
    //     .filter(b => b.platform === 'linux' && b.extendedPlatform.some(p => /opensuse/i.test(p)));
    // let openSuseBuildItems = [];
    // if(openSuseBuilds.length > 0) {
    //
    //     const latestOpenSuseVersion = openSuseBuilds[0].version;
    //
    //     openSuseBuildItems = openSuseBuilds
    //         .filter(b => b.version = latestOpenSuseVersion)
    //         .map(b => {
    //             return (
    //                 <li style={styles.downloadListItem} key={b.key}>
    //                     <a href={b.link}><strong>{`Map Creator v${b.version} for ${b.arch}-bit ${b.extendedPlatform.join(' / ')}`}</strong></a>
    //                     <span className="text-muted">{` - ${formatDate(b.createdAt)} | ${b.size}`}</span>
    //                 </li>
    //             );
    //         });
    // }

    const DownloadPanel = ({ panelTitleClass, panelTitleText, panelBodyText, buildItems }) => {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="panel panel-success">
                        <div className="panel-heading">
                            <h3 className="panel-title"><i className={panelTitleClass} /> {panelTitleText}</h3>
                        </div>
                        <div className="panel-body">
                            <p className="text-muted">{panelBodyText}</p>
                            {buildItems && buildItems.length > 0 ?
                                <ul>
                                    {buildItems}
                                </ul>
                                :
                                <ul />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    DownloadPanel.propTypes = {
      panelTitleClass: PropTypes.string,
      panelTitleText: PropTypes.string,
      panelBodyText: PropTypes.string,
      buildItems: PropTypes.arrayOf(PropTypes.object),
    };

    return (
        <div>
            <PageHeader><span>{'Map Creator Downloads'}</span></PageHeader>
            <div className="row">
                <div className="col-sm-12">
                    <div className="panel panel-success">
                        <div className="panel-heading">
                            <h3 className="panel-title">{'Map Creator Videos'}</h3>
                        </div>
                        <div className="panel-body">
                            {tutorialVideo.key ?
                                <ul>
                                    <li><a style={{fontWeight: 'bold'}} href={tutorialVideo.link}>{'Map Creator Tutorial'}</a><span className="text-muted">{` - February 11, 2013 | ${sizeHash[tutorialVideo.key]}`}</span></li>
                                </ul>
                                :
                                <ul />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <DownloadPanel
                panelTitleClass="fa fa-windows"
                panelTitleText="Windows"
                panelBodyText="Requirements: Map Creator will run on 32- or 64-bit versions of Windows 7 through Windows 10.  We are not aware of any reason why Map Creator will not run on older versions of Windows, but older Windows versions have not been tested."
                buildItems={mapCreatorWindowsBuildItems}
            />

            <DownloadPanel
                panelTitleClass="fa fa-apple"
                panelTitleText="macOS"
                panelBodyText="Requirements: Map Creator will run on Mac OS X 10.10 'Yosemite' and later.  We are not aware of any reason why Map Creator will not run on Mac OS X as far back as 10.6 'Snow Leopard', but older versions of OS X have not been tested."
                buildItems={mapCreatorMacosBuildItems}
            />

            {/*<div className="row">*/}
            {/*    <div className="col-sm-12">*/}
            {/*        <div className="panel panel-success">*/}
            {/*            <div className="panel-heading">*/}
            {/*                <h3 className="panel-title"><i className="fa fa-linux"></i> {'Ubuntu Linux / Linux Mint'}</h3>*/}
            {/*            </div>*/}
            {/*            <div className="panel-body">*/}
            {/*                <p className="text-muted">{'Choose the correct download for your distribution and architecture. These downloads should also work for similar Debian-based distributions, although you may have to manually resolve some of the dependencies.'}</p>*/}
            {/*                <ul>*/}
            {/*                    {ubuntuBuildItems}*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="row">*/}
            {/*    <div className="col-sm-12">*/}
            {/*        <div className="panel panel-success">*/}
            {/*            <div className="panel-heading">*/}
            {/*                <h3 className="panel-title"><i className="fa fa-linux"></i> {'openSUSE Linux'}</h3>*/}
            {/*            </div>*/}
            {/*            <div className="panel-body">*/}
            {/*                <p className="text-muted">{'Choose the correct download for your distribution and architecture. These downloads should also work for similar RPM-based distributions, although you may have to manually resolve some of the dependencies.'}</p>*/}
            {/*                <ul>*/}
            {/*                    {openSuseBuildItems}*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/* eslint-disable-next-line */}
            <PageHeader><span>{'Image Finder Downloads'}</span></PageHeader>
            <h3 style={styles.warning}><strong className="text-danger">Once images are loaded, Image Finder will require 2.5 GB on your hard drive.</strong></h3>
            <DownloadPanel
                panelTitleClass="fa fa-windows"
                panelTitleText="Windows"
                panelBodyText="Requirements: Image Finder will run on 32- or 64-bit versions of Windows 7 through Windows 10.  We are not aware of any reason why Image Finder will not run on older versions of Windows, but older Windows versions have not been tested."
                buildItems={imageFinderWindowsBuildItems}
            />

            <DownloadPanel
                panelTitleClass="fa fa-apple"
                panelTitleText="macOS"
                panelBodyText="Requirements: Image Finder will run on macOS 10.12 'Sierra' and later.  We are not aware of any reason why Image Finder will not run on Mac OS X as far back as 10.6 'Snow Leopard', but older versions of OS X have not been tested."
                buildItems={imageFinderMacosBuildItems}
            />

        </div>
    );
};
DownloadMapCreator.propTypes = {
    files: PropTypes.arrayOf(PropTypes.object),
    builds: PropTypes.arrayOf(PropTypes.object),
};

export default DownloadMapCreator;
