import React from 'react';
import PageHeader from '../shared/page-header';

const Contact = () => {
    return (
        <div>
            <PageHeader><span>Support and Contact Information</span></PageHeader>
            <div className="row">
                <div className="col-sm-12">
                    <div>
                        <div>
                            <p style={{fontSize: 18}}>Email: <a href="mailto:help@fmosoft.com"><strong>help@fmosoft.com</strong></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Contact.propTypes = {};

export default Contact;
