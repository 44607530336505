import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();

    const styles = {
        jumbotron: {
            backgroundColor: '#e1d7c4',
            paddingTop: 38,
            paddingBottom: 68,
            marginBottom: 0,
        },
        flexContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'center',
        },
        imageContainer: {
            width: 130,
            height: 'auto',
            marginTop: 20,
        },
        titleContainer: {
            marginLeft: 20,
        },
        title: {
            marginBottom: 5,
            fontSize: 68,
            cursor: 'default',
            fontFamily: 'Rajdhani, "Open Sans", sans-serif',
        },
        subtitle: {
            marginTop: 0,
            cursor: 'default',
            fontSize: 16,
            lineHeight: '30px',
            fontStyle: 'italic',
        },
        navbarContainer: {
            backgroundColor: '#222',
            height: 50,
            marginBottom: 20,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'center',
        },
        navbarItemContainer: {},
        navItem: {},
    };

    const navItem = (path, icon, text) => {
        const iconClass = `fa ${icon}`;

        return (
            <div className="navbar-item-container" style={styles.navbarItemContainer}>
                <Link to={path}>
                    <div className={location.pathname === path ? 'active' : ''} style={styles.navItem}>
                        <i className={iconClass} aria-hidden="true" /> {text}
                    </div>
                </Link>
            </div>
        );
    };

    return (
        <div>
            <div className="jumbotron" style={styles.jumbotron}>
                <div style={styles.flexContainer}>
                    <div className="title-container" style={styles.titleContainer}>
                        <h1 style={styles.title}>{'FMOsoft'}</h1>
                        <h3 className="subtitle-header" style={styles.subtitle}>{'Tools you need'}</h3>
                    </div>
                </div>
            </div>
            <nav>
                <div style={styles.navbarContainer}>
                    {navItem('/', 'fa-home', 'Home')}
                    {navItem('/map-creator', 'fa-hashtag', 'Map Creator')}
                    {navItem('/image-finder', 'fa-hashtag', 'Image Finder')}
                    {navItem('/download', 'fa-arrow-circle-o-down', 'Downloads')}
                    {navItem('/contact', 'fa-envelope-o', 'Contact')}
                </div>
            </nav>
        </div>
    );
};
Header.propTypes = {
    pathname: PropTypes.string,
};
export default Header;
