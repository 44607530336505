/* eslint semi: 1 */

const defaultAppData = {
    builds: [],
    files: [],
};

export default (state = defaultAppData, action) => {

    const { type, payload } = action;

    switch(type) {
        case 'ADD_BUILDS':
            return {
                ...state,
                builds: [...state.builds, ...payload.builds],
            };
        case 'ADD_FILES':
            return {
                ...state,
                files: [...state.files, ...payload.files],
            };
        default:
            return state;
    }

};
