import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/shared/header';
import Footer from './components/shared/footer';

const App = () => {
    const styles = {
        bodyContainer: {
            backgroundColor: '#fff',
            // paddingLeft: 15,
            // paddingRight: 15,
            paddingBottom: 15,
            // paddingTop: 5
        },
    };
    return (
        <div>
            <Header />
            <div className="container" style={styles.bodyContainer}>
              <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default App;
