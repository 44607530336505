import React from 'react';
// import PageHeader from '../shared/page-header';
import ImageGallery from 'react-image-gallery';

const MapCreator = () => {

    // comment out the Crossway images.  Hopefully someday they'll give us permission again.
    const carouselImages = [
        {original: '/images/carousel/01.jpg', thumbnail: '/images/carousel/01-thumb.jpg'},
        {original: '/images/carousel/02.jpg', thumbnail: '/images/carousel/02-thumb.jpg'},
        {original: '/images/carousel/03.jpg', thumbnail: '/images/carousel/01-thumb.jpg'},
        {original: '/images/carousel/04.jpg', thumbnail: '/images/carousel/04-thumb.jpg'},
        //{original: '/images/carousel/05.jpg', thumbnail: '/images/carousel/05-thumb.jpg'},
        {original: '/images/carousel/06.jpg', thumbnail: '/images/carousel/06-thumb.jpg'},
        {original: '/images/carousel/07.jpg', thumbnail: '/images/carousel/07-thumb.jpg'},
        {original: '/images/carousel/08.jpg', thumbnail: '/images/carousel/08-thumb.jpg'},
        {original: '/images/carousel/09.jpg', thumbnail: '/images/carousel/09-thumb.jpg'},
        {original: '/images/carousel/10.jpg', thumbnail: '/images/carousel/10-thumb.jpg'},
        {original: '/images/carousel/11.jpg', thumbnail: '/images/carousel/11-thumb.jpg'},
        {original: '/images/carousel/12.jpg', thumbnail: '/images/carousel/12-thumb.jpg'},
        {original: '/images/carousel/13.jpg', thumbnail: '/images/carousel/13-thumb.jpg'},
        {original: '/images/carousel/14.jpg', thumbnail: '/images/carousel/14-thumb.jpg'},
        //{original: '/images/carousel/15.jpg', thumbnail: '/images/carousel/15-thumb.jpg'},
        {original: '/images/carousel/16.jpg', thumbnail: '/images/carousel/16-thumb.jpg'},
        {original: '/images/carousel/17.jpg', thumbnail: '/images/carousel/17-thumb.jpg'},
        {original: '/images/carousel/18.jpg', thumbnail: '/images/carousel/18-thumb.jpg'},
        {original: '/images/carousel/19.jpg', thumbnail: '/images/carousel/19-thumb.jpg'},
        {original: '/images/carousel/20.jpg', thumbnail: '/images/carousel/20-thumb.jpg'},
        {original: '/images/carousel/21.jpg', thumbnail: '/images/carousel/21-thumb.jpg'},
        {original: '/images/carousel/22.jpg', thumbnail: '/images/carousel/22-thumb.jpg'},
        //{original: '/images/carousel/23.jpg', thumbnail: '/images/carousel/23-thumb.jpg'},
        //{original: '/images/carousel/24.jpg', thumbnail: '/images/carousel/24-thumb.jpg'},
        {original: '/images/carousel/25.jpg', thumbnail: '/images/carousel/25-thumb.jpg'},
        {original: '/images/carousel/26.jpg', thumbnail: '/images/carousel/26-thumb.jpg'},
    ];

    return (
        <div>
            {/*<PageHeader><span>Map Creator</span></PageHeader>*/}
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 style={{marginTop: 30, marginBottom: 30}}>Map Creator simplifies the creation of language-specific maps, charts, diagrams, and timelines for Bible lessons and world geography.</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <ImageGallery items={carouselImages} slideInterval={3000} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <div className="well">
                                <h3>Features</h3>
                                <ul>
                                    <li>80 maps, charts, and diagrams for chronological Bible teaching and world geography</li>
                                    <li>Powerful editing features</li>
                                    <li>Create translatable maps, charts, diagrams, and timelines from your own images</li>
                                    <li>Runs on Windows and Mac OS X</li>
                                    <li>User interface and predefined map content in English, Spanish, Portuguese, French, and Indonesian</li>
                                    <li>Many Tok Pisin resources included</li>
                                    <li>Translate once: translations for one map are remembered for future maps</li>
                                    <li>Share map files with other Map Creator users</li>
                                    <li>Export to JPG, PNG, or PDF images</li>
                                    <li>Print or export to any size from tiny Bible insets to 6-foot posters</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 col-md-offset-1">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" src={'https://player.vimeo.com/video/59357958'} allowFullScreen={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
MapCreator.propTypes = {};

export default MapCreator;
