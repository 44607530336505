import React from 'react';
import imageFinder1 from '../../public/images/image-finder1.png';

// const prepImagePath = p => p
//     .replace(/^c:\\/, '')
//     .replace(/\\/g, '/');

const ImageFinder = () => {
    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        img: {
            width: '100%',
            marginBottom: 10,
        },
    };

    return (
        <div>
            {/*<PageHeader><span>Map Creator</span></PageHeader>*/}
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 style={{marginTop: 30, marginBottom: 30}}>Find the line drawing you want
                                by searching for the items you need.</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <img style={styles.img} src={imageFinder1} alt="Image Finder" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <div className="well">
                                <h3>Features</h3>
                                <ul>
                                    <li>Over 20,000 images</li>
                                    <li>Search for images that contain the items you want</li>
                                    <li>Supports 5 languages: English, French, Spanish, Portuguese, Indonesian</li>
                                    <li>Runs on Windows and MacOS</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
ImageFinder.propTypes = {};

export default ImageFinder;
